import { HttpClient } from "@/http/HttpClient";

export const AdditionalItemHttpClient = {
  reserveItems(eventUuid, showUuid, tickets) {
    return HttpClient().post(
      `/event/${eventUuid}/shows/${showUuid}/additional-items/reserve`,
      tickets
    );
  }
};
