<template>
  <div class="d-flex col my-3">
    <div v-if="isPending" class="d-flex flex-column text-center flex-grow-1">
      <div class="col-lg-6 offset-lg-3 flex-grow-1">
        <cart></cart>
      </div>
      <div
        class="custom-control custom-checkbox"
        v-if="newsletterSignupText !== ('' || null)"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="newsletter"
          v-model="subscribe"
        />
        <label
          class="custom-control-label mt-4 text-left user-select-none"
          for="newsletter"
        >
          <span> {{ newsletterSignupText }}</span>
        </label>
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="terms"
          v-model="termsAccepted"
        />
        <label
          class="custom-control-label mt-4 text-left user-select-none"
          for="terms"
        >
          <span
            v-if="!hasTerms"
            v-html="
              $t('payment.termsHeader') +
                ' ' +
                $t('payment.terms', {
                  link: 'https://tickethero.dk/handelsbetingelser/'
                })
            "
          ></span>
          <span v-if="hasTerms" v-html="$t('payment.termsHeader')"></span>
          <ul v-if="hasTerms">
            <li
              v-html="
                $t('payment.terms', {
                  link: 'https://tickethero.dk/handelsbetingelser/'
                })
              "
            ></li>
            <li
              v-html="
                $t('payment.organizerTerms', {
                  link:
                    'https://tickethero.dk/portal/' +
                    organizerSlug +
                    '/' +
                    eventSlug +
                    '/betingelser',
                  organizer: organizerName
                })
              "
            ></li>
          </ul>
        </label>
      </div>
      <div>
        <button
          class="btn btn-success mt-5"
          :disabled="!termsAccepted"
          @click="checkout"
        >
          <template v-if="orderSum === 0">
            {{ $t("payment.action.complete") }}
          </template>
          <template v-else>
            {{ $t("payment.action.pay") }}
          </template>
        </button>
      </div>
    </div>
    <div
      v-if="isProcessing"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    >
      <div class="align-self-center mb-5">
        <font-awesome-icon
          :icon="['fal', 'spinner']"
          size="7x"
          spin
        ></font-awesome-icon>
      </div>
      <div class="align-self-center text-center">
        <h3>{{ $t("payment.status.awaiting.title") }}</h3>
        <a :href="paymentLink" target="_blank">{{
          $t("payment.status.awaiting.message")
        }}</a>
      </div>
    </div>
    <div
      v-if="orderCompleted"
      class="flex-grow-1 align-self-center text-center"
    >
      <div class="mb-5">
        <div class="d-flex flex-column flex-lg-row justify-content-center">
          <span class="text-success position-relative h1">
            <font-awesome-icon
              :icon="['fal', 'check']"
              size="lg"
              style="left: -3.4rem"
              class="position-lg-absolute"
            ></font-awesome-icon>
          </span>
          <span class="align-self-center h1">
            {{ $t("payment.status.completed.title") }}
          </span>
        </div>
        <p
          class="h5"
          v-html="
            $t('payment.transactionCompletedMessage.orderId', { id: orderId })
          "
        ></p>
      </div>
      <div
        class="d-flex flex-wrap justify-content-center px-1 px-sm-3 px-md-3 px-lg-4 px-xl-8"
        style="margin: auto; row-gap: 0.7rem; column-gap: 0.5ch"
      >
        <div
          class="d-flex flex-column justify-content-center px-1 px-sm-3 px-md-3 px-lg-4 px-xl-8"
          style="gap: 0 0.5ch"
        >
          <p
            style="margin-bottom: 0"
            v-html="
              $t('payment.transactionCompletedMessage.mailInformation', {
                mail: email
              })
            "
          ></p>
          <p v-html="$t('payment.transactionCompletedMessage.verifyMail')"></p>
        </div>
        <p v-html="$t('payment.transactionCompletedMessage.spamReminder')"></p>
        <p v-html="$t('payment.transactionCompletedMessage.loginRef')"></p>
        <p
          v-if="emailType(email) === 'gmail'"
          v-html="
            $t('payment.transactionCompletedMessage.ifUsing', {
              email: emailType(email)
            }) + $t('payment.transactionCompletedMessage.gmail')
          "
        ></p>
        <p
          v-if="outlook.includes(emailType(email))"
          v-html="
            $t('payment.transactionCompletedMessage.ifUsing', {
              email: emailType(email)
            }) + $t('payment.transactionCompletedMessage.outlook')
          "
        ></p>
      </div>
    </div>
    <div
      v-if="orderCancelled"
      class="flex-grow-1 align-self-center text-center"
    >
      <div class="align-self-center mb-5 text-danger">
        <font-awesome-icon
          :icon="['fal', 'times']"
          size="7x"
        ></font-awesome-icon>
      </div>
      <h1 class="h3">{{ $t("payment.status.cancelled.title") }}</h1>
    </div>
    <div v-if="overLimit" class="flex-grow-1 align-self-center text-center">
      <div class="align-self-center mb-5 text-danger">
        <font-awesome-icon
          :icon="['fal', 'times']"
          size="7x"
        ></font-awesome-icon>
      </div>
      <h1 class="h3">{{ $t("payment.status.overLimit.title") }}</h1>
      <p>{{ $t("payment.status.overLimit.description") }}</p>
      <button @click="restartFlow(true)" class="btn btn-success mt-5">
        {{ $t("payment.status.overLimit.button") }}
      </button>
    </div>
  </div>
</template>

<script>
import { CartHttpClient } from "@/http/CartHttpClient";
import Cart from "@/components/Cart";
import ApiResponseCode from "@/enums/ApiResponseCode";
import { RestartMixin } from "@/mixins/RestartMixin";

export default {
  name: "Payment",
  components: { Cart },
  mixins: [RestartMixin],
  data: function() {
    return {
      paymentLink: null,
      isPending: true,
      isProcessing: false,
      orderCompleted: false,
      orderCancelled: false,
      overLimit: false,
      termsAccepted: false,
      subscribe: false,
      outlook: ["outlook", "live", "hotmail"],
      email: "error@error.com",
      orderId: null
    };
  },
  computed: {
    orderSum: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/totalPrice"
      ];
    },
    hasTerms: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/hasTerms"
      ];
    },
    newsletterSignupText: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/newsletterSignupText"
      ];
    },
    eventSlug: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/eventSlug"
      ];
    },
    organizerSlug: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/organizerSlug"
      ];
    },
    organizerName: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/organizerName"
      ];
    }
  },
  methods: {
    checkout() {
      this.isPending = false;
      this.isProcessing = true;
      const orderQuestions = this.$store.getters[
        this.$store.state.shownWidget + "/order/orderQuestions"
      ];
      const language = this.$store.getters["global/language"];
      CartHttpClient.checkout(orderQuestions, this.subscribe, language)
        .then(response => {
          this.email = response.data.email;
          this.orderId = response.data.orderNumber;
          this.$store.commit(
            this.$store.state.shownWidget + "/order/setOrderNumber",
            response.data.orderNumber
          );
          if (Object.prototype.hasOwnProperty.call(response.data, "link")) {
            this.paymentLink = response.data.link;
            const windowObject = window.open(this.paymentLink, "_blank");
            this.$ws
              .channel(
                `order.${
                  this.$store.getters[
                    this.$store.state.shownWidget + "/order/orderNumber"
                  ]
                }`
              )
              .listen("order_completed", () => {
                if (
                  this.organizerSlug !== "circus-of-torment" ||
                  this.organizerSlug !== "dystopia"
                ) {
                  windowObject.close();
                }
                this.isProcessing = false;
                this.orderCompleted = true;
                this.$store.commit(
                  this.$store.state.shownWidget +
                    "/order/setReservationExpired",
                  false
                );
                this.$store.dispatch(
                  this.$store.state.shownWidget + "/order/emptyCart"
                );
              })
              .listen("order_cancelled", () => {
                windowObject.close();
                this.isProcessing = false;
                this.orderCancelled = true;
                this.$store.commit(
                  this.$store.state.shownWidget +
                    "/order/setReservationExpired",
                  false
                );
                this.$store.dispatch(
                  this.$store.state.shownWidget + "/order/emptyCart"
                );
              });
          } else {
            this.isProcessing = false;
            this.orderCompleted = true;
            this.$store.commit(
              this.$store.state.shownWidget + "/order/setReservationExpired",
              false
            );
            this.$store.dispatch(
              this.$store.state.shownWidget + "/order/emptyCart"
            );
          }
        })
        .catch(e => {
          if (
            e?.response?.data?.error?.code === ApiResponseCode.CART_IS_EMPTY
          ) {
            this.isProcessing = false;
            this.$store.commit(
              this.$store.state.shownWidget + "/order/setReservationExpired",
              true
            );
          }

          if (
            e?.response?.data?.error?.code ===
            ApiResponseCode.MAXIMUM_TICKET_AMOUNT_REACHED
          ) {
            this.isProcessing = false;
            this.overLimit = true;
          }
        });
    },
    emailType(email) {
      let match = /^.*@(?<mailType>.+)\..*/.exec(email);
      if (match !== null) {
        return match.groups.mailType;
      } else {
        return "Unknown";
      }
    }
  }
};
</script>
