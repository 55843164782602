import Vue from "vue";
import Vuex from "vuex";
import { Global } from "@/store/global";
import { Order } from "@/store/order";
import { Event } from "@/store/event";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global: Global,
    order: Order,
    event: Event
  },
  state: () => ({
    shownWidget: null
  }),
  mutations: {
    setShownWidget(state, uuid) {
      state.shownWidget = uuid;
    }
  }
});
