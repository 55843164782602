<template>
  <span class="badge" :class="{ 'badge-dot': badgeDesign === 'dot' }">
    <span v-if="status === TicketStatus.PLENTY_TICKETS_LEFT">
      <i class="bg-ticket-plenty-left"></i>
      <span class="text-ticket-plenty-left user-select-none">
        <template v-if="badgeDesign === 'dot'">{{
          $t("ticket.status.plentyLeft")
        }}</template>
        <span
          class="user-select-none"
          v-else
          v-html="$t('ticket.statusMultiline.plentyLeft')"
        ></span>
      </span>
    </span>

    <span v-if="status === TicketStatus.FEW_TICKETS_LEFT">
      <i class="bg-ticket-few-left"></i>
      <span class="text-ticket-few-left user-select-none">
        <template v-if="badgeDesign === 'dot'">{{
          $t("ticket.status.fewLeft")
        }}</template>
        <span
          class="user-select-none"
          v-else
          v-html="$t('ticket.statusMultiline.fewLeft')"
        ></span>
      </span>
    </span>

    <span v-if="status === TicketStatus.SOLD_OUT">
      <i class="bg-ticket-sold-out"></i>
      <span class="text-ticket-sold-out user-select-none">
        <template v-if="badgeDesign === 'dot'">{{
          $t("ticket.status.soldOut")
        }}</template>
        <span
          class="user-select-none"
          v-else
          v-html="$t('ticket.statusMultiline.soldOut')"
        ></span>
      </span>
    </span>

    <span v-if="status === 0">
      <i class=""></i>
      <span class="user-select-none">
        <template v-if="badgeDesign === 'dot'">{{
          $t("ticket.status.soldOut")
        }}</template>
        <span
          class="user-select-none"
          v-else
          v-html="$t('ticket.statusMultiline.soldOut')"
        ></span>
      </span>
    </span>

    <span v-if="status === -1">
      <i class=""></i>
      <span class="user-select-none">
        <template v-if="badgeDesign === 'dot'">{{
          $t("ticket.status.finished")
        }}</template>
        <span v-else v-html="$t('ticket.statusMultiline.finished')"></span>
      </span>
    </span>
  </span>
</template>

<script>
import TicketStatus from "@/enums/TicketStatus";
export default {
  name: "TicketStatusBadge",
  props: {
    status: {
      type: Number,
      default: null,
      required: true
    },
    badgeDesign: {
      type: String,
      default: "dot",
      required: false
    }
  },
  data: function() {
    return {
      TicketStatus: TicketStatus
    };
  }
};
</script>
