import { HttpClient } from "@/http/HttpClient";

export const CartHttpClient = {
  getCart() {
    return HttpClient().get(`/cart`);
  },
  emptyCart() {
    return HttpClient().post(`/cart/empty`);
  },
  checkout(orderQuestions, subscribe, language) {
    return HttpClient().post(`/cart/checkout`, {
      orderQuestions: orderQuestions,
      subscribe: subscribe,
      language: language
    });
  },
  redeemCoupon(coupon) {
    return HttpClient().post("/cart/redeem-coupon", { coupon: coupon });
  }
};
