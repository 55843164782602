<template>
  <div class="p-3">
    <div v-show="!showLogin && !showForgotPassword">
      <h1 class="h3">{{ $t("buyerInformation.createTitle") }}</h1>
      <div>
        <div class="alert alert-warning" v-if="customError !== null">
          {{ customError }}
        </div>
        <validation-observer ref="createForm">
          <TEmailInput
            :rules="{ required: true }"
            v-model="createData.email"
            trim
            :validation-interaction-mode="'lazy'"
            :label="$t('buyerInformation.email')"
          ></TEmailInput>
          <div class="form-row">
            <div class="col-6">
              <TInput
                :label="$t('buyerInformation.firstname')"
                :rules="{ required: true }"
                v-model="createData.first_name"
              ></TInput>
            </div>
            <div class="col-6">
              <TInput
                :label="$t('buyerInformation.lastname')"
                :rules="{ required: true }"
                v-model="createData.last_name"
              ></TInput>
            </div>
          </div>
          <template v-if="!widgetIdsToHideFields.includes(createData.event)">
            <TInput
              :label="$t('buyerInformation.phone')"
              :rules="{ required: true }"
              v-model="createData.phone"
            ></TInput>
            <t-address-search
              :label="$t('buyerInformation.address')"
              :axios="httpClient"
              @input="addressSelected"
            ></t-address-search>
          </template>
          <!--          <TCheckbox-->
          <!--            v-model="createData.create"-->
          <!--            :label="$t('buyerInformation.action.createAccount')"-->
          <!--          ></TCheckbox>-->

          <div
            v-if="accountExists && createData.create"
            v-html="$t('buyerInformation.accountExists')"
          ></div>
          <div
            v-if="!accountExists && accountExists !== null && createData.create"
          >
            {{ $t("buyerInformation.noAccount") }}
            <t-password-input
              v-model="createData.password"
              :label="$t('buyerInformation.choosePassword')"
              :rules="{ required: true }"
            ></t-password-input>
            <TCheckbox
              :rules="{ required: { allowFalse: false } }"
              v-model="createData.terms"
              :name="$t('buyerInformation.termsErrorName')"
              :label="
                $t('buyerInformation.terms', {
                  link: 'https://tickethero.dk/handelsbetingelser/'
                })
              "
            ></TCheckbox>
          </div>
        </validation-observer>
        <div class="d-flex justify-content-end">
          <t-submit-button
            :btn-classes="{ 'btn-success float-right mt-3': true }"
            :form="$refs.createForm"
            :btn-text="$t('action.continue')"
            :action="store"
          ></t-submit-button>
        </div>
        <!--        <p-->
        <!--          class="text-right pointer"-->
        <!--          @click="-->
        <!--            showLogin = true;-->
        <!--            showForgotPassword = false;-->
        <!--          "-->
        <!--        >-->
        <!--          {{ $t("buyerInformation.aldreadyHasAnAccountShort") }}-->
        <!--        </p>-->
      </div>
    </div>
    <div v-show="showLogin && !showForgotPassword">
      <h1 class="h3">{{ $t("buyerInformation.loginTitle") }}</h1>
      <div class="alert alert-dark">
        {{ $t("buyerInformation.dontHaveAnAccount") }}
        <span
          class="alert-link pointer"
          @click="
            showLogin = false;
            showForgotPassword = false;
          "
        >
          {{ $t("buyerInformation.action.createAccountHere") }}
        </span>
      </div>

      <validation-observer ref="loginForm">
        <TInput
          :label="$t('buyerInformation.email')"
          v-model="loginData.email"
          :rules="{ required: true, email: true }"
        ></TInput>
        <TPasswordInput
          :label="$t('buyerInformation.password')"
          v-model="loginData.password"
          :rules="{ required: true }"
          no-validation
        ></TPasswordInput>
      </validation-observer>
      <div class="text-warning" v-if="loginFailed">
        {{ $t("buyerInformation.error.loginFailed") }}
      </div>
      <div class="d-flex justify-content-end flex-column">
        <t-submit-button
          :btn-classes="{ 'btn-success float-right mt-3': true }"
          :form="$refs.loginForm"
          :btn-text="$t('buyerInformation.action.login')"
          :action="login"
        ></t-submit-button>
        <span class="mt-2 text-right">
          <span
            class="pointer"
            @click="
              showLogin = false;
              showForgotPassword = true;
            "
            >{{ $t("buyerInformation.forgotPassword") }}</span
          >
        </span>
      </div>
    </div>
    <div v-show="showLogin === false && showForgotPassword === true">
      <p
        class="pointer"
        @click="
          showForgotPassword = false;
          showLogin = true;
        "
      >
        <FontAwesomeIcon
          :icon="['fal', 'chevron-left']"
          @click="showModal = false"
        ></FontAwesomeIcon>
        {{ $t("buyerInformation.action.backToLogin") }}
      </p>
      <validation-observer ref="forgotPasswordForm">
        <TInput
          :label="$t('buyerInformation.email')"
          v-model="forgotPasswordData.email"
          :rules="{ required: true, email: true }"
        ></TInput>
      </validation-observer>
      <div class="d-flex justify-content-end flex-column text-right">
        <t-submit-button
          :btn-classes="{ 'btn-success float-right mt-3': true }"
          :btn-text="$t('buyerInformation.action.resetPassword')"
          :form="$refs.forgotPasswordForm"
          :action="resetPassword"
        ></t-submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TPasswordInput,
  TSubmitButton,
  TAddressSearch,
  TInput,
  TEmailInput,
  TCheckbox
} from "@tickethero/vue-component-kit";
import { HttpClient } from "@/http/HttpClient";
import { UserHttpClient } from "@/http/UserHttpClient";
import { ValidationHttpClient } from "@/http/ValidationHttpClient";

export default {
  name: "BuyerInformation",
  components: {
    TPasswordInput,
    TSubmitButton,
    TAddressSearch,
    TInput,
    TEmailInput,
    TCheckbox
  },
  data: function() {
    return {
      widgetIdsToHideFields: ["vwkPZyzx4w2dYWn3qOlJ", "QbdBoWLP4LDX4J0RKDO1", "e0QoZjdz8RzL8bVEapWG"],
      customError: null,
      httpClient: HttpClient(),
      accountExists: null,
      showLogin: false,
      showForgotPassword: false,
      createData: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        password: "",
        login: true,
        street: "",
        postal_code: "",
        city: "",
        country_code: "DK",
        terms: false,
        create: false,
        event: null
      },
      loginData: {
        email: "",
        password: ""
      },
      forgotPasswordData: {
        email: ""
      },
      loginFailed: false,
      nextPage: "Payment"
    };
  },
  watch: {
    "createData.email": function() {
      this.accountExists = null;
      this.createData.create = false;
    },
    "createData.create": function(val) {
      if (
        !val ||
        this.createData.email === null ||
        this.createData.email.trim() === ""
      ) {
        return;
      }
      this.checkEmail();
    }
  },
  methods: {
    checkEmail() {
      this.accountExists = null;
      ValidationHttpClient.userEmailUnique({ email: this.createData.email })
        .then(() => {
          this.accountExists = false;
        })
        .catch(() => {
          this.accountExists = true;
        });
    },
    addressSelected(address) {
      this.createData.country_code = address.countryShort ?? "DK";
      this.createData.postal_code = address.zipCode;
      this.createData.street = address.address;
      this.createData.city = address.city;
    },
    store() {
      this.customError = null;
      this.createData.event = this.$store.getters[
        this.$store.state.shownWidget + "/event/eventUuid"
      ];

      if (this.widgetIdsToHideFields.includes(this.createData.event)) {
        this.createData.phone = "33186099";
        this.createData.street = "Koldinghus 1";
        this.createData.postal_code = "6000";
        this.createData.city = "Kolding";
        this.createData.country_code = "DK";
      }

      this.$store
        .dispatch(
          this.$store.state.shownWidget + "/global/storeUser",
          this.createData
        )
        .then(() => {
          this.$store.commit(
            this.$store.state.shownWidget + "/global/changePage",
            this.nextPage
          );
        })
        .catch(error => {
          this.customError = error?.response?.data?.customMessage;
        });
    },
    login() {
      this.loginFailed = false;
      this.$store
        .dispatch(
          this.$store.state.shownWidget + "/global/login",
          this.loginData
        )
        .then(() => {
          this.$store.commit(
            this.$store.state.shownWidget + "/global/changePage",
            this.nextPage
          );
        })
        .catch(() => {
          this.loginFailed = true;
        });
    },
    resetPassword() {
      UserHttpClient.requestPasswordReset(this.forgotPasswordData).then(() => {
        this.showForgotPassword = false;
        this.showLogin = true;
      });
    }
  },
  created() {
    this.createData.event = this.$store.getters[
      this.$store.state.shownWidget + "/event/eventUuid"
    ];

    this.$store
      .dispatch(
        this.$store.state.shownWidget + "/event/fetchOrderQuestions",
        this.$store.getters[this.$store.state.shownWidget + "/event/eventUuid"]
      )
      .then(() => {
        if (
          this.$store.getters[
            this.$store.state.shownWidget + "/event/orderQuestions"
          ].length !== 0
        ) {
          this.nextPage = "OrderQuestion";
        }
      });
  }
};
</script>
