<template>
  <div class="d-flex flex-grow-1 flex-column p-3">
    <div class="flex-grow-1">
      <h1 class="h3 mb-3">
        {{ show.name }}
        <span class="d-inline-block text-uppercase-first">{{
          show.starts_at.toLocaleString($dateTimeFormat.DATETIME_WITH_WEEKDAY)
        }}</span>
      </h1>
      <div v-for="item in items" :key="item.uuid" class="row mb-5">
        <div class="col-12 col-md-4">
          <TImage :src="item.image"></TImage>
        </div>
        <div class="col-12 col-md-8">
          <h1 class="h3 mb-0">{{ item.name }}</h1>
          <span class="badge badge-primary mb-3">
            <TCurrency
              :amount="[item.minPrice, item.maxPrice]"
              v-if="item.minPrice !== item.maxPrice"
            ></TCurrency>
            <TCurrency :amount="item.minPrice" v-else></TCurrency>
          </span>
          <p v-html="item.description"></p>
          <TSelect
            v-if="item.items.length > 1"
            v-model="selectedItems[item.uuid].selectedOption"
            track-by="uuid"
            :options="item.items"
            :placeholder="$t('additionalItems.variation')"
          >
            <template v-slot:default="{ option }">
              {{ option.name }}<br />
              <small>
                <TCurrency :amount="option.price"></TCurrency> +
                {{ $t("fee") }}
                <TCurrency :amount="option.fee"></TCurrency>
              </small>
            </template>
          </TSelect>
          <div class="row">
            <div class="col-12 col-lg-6">
              <TNumberInput
                v-model="selectedItems[item.uuid].selectedAmount"
              ></TNumberInput>
            </div>
            <div class="col-12 col-lg-6 mt-3 mt-lg-0">
              <button class="btn btn-primary col" @click="addToCart(item)">
                {{ $t("cart.action.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button class="btn btn-success float-right" @click="nextPage">
          {{ $t("action.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TImage,
  TSelect,
  TNumberInput,
  TCurrency
} from "@tickethero/vue-component-kit";

export default {
  name: "AdditionalItems",
  components: { TNumberInput, TSelect, TImage, TCurrency },
  data: function() {
    return {
      items: [],
      selectedItems: {}
    };
  },
  computed: {
    show: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/activeShow"
      ];
    }
  },
  methods: {
    maxPrice(items) {
      return items.reduce(
        (prev, current) => (prev.price > current.price ? prev : current),
        0
      ).price;
    },
    minPrice(items) {
      return items.reduce(
        (prev, current) => (prev.price < current.price ? prev : current),
        0
      ).price;
    },
    addToCart(item) {
      if (this.selectedItems[item.uuid].selectedAmount === 0) {
        return;
      }
      if (
        this.selectedItems[item.uuid].selectedOption === null &&
        item.items.length === 1
      ) {
        this.selectedItems[item.uuid].selectedOption = item.items[0];
      }
      if (this.selectedItems[item.uuid].selectedOption === null) {
        return;
      }
      this.$store
        .dispatch(this.$store.state.shownWidget + "/order/addItems", {
          eventUuid: this.$store.getters[
            this.$store.state.shownWidget + "/event/eventUuid"
          ],
          showUuid: this.$store.getters[
            this.$store.state.shownWidget + "/event/activeShow"
          ].uuid,
          payload: {
            items: [
              {
                id: this.selectedItems[item.uuid].selectedOption.uuid,
                amount: this.selectedItems[item.uuid].selectedAmount
              }
            ]
          }
        })
        .then(() => {
          this.selectedItems[item.uuid] = {
            selectedOption: null,
            selectedAmount: 0
          };
        });
    },
    nextPage() {
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "BuyerInformation"
      );
    }
  },
  created() {
    let temp = {};
    this.$store
      .dispatch(
        this.$store.state.shownWidget + "/event/fetchAdditionalItems",
        this.$store.getters[this.$store.state.shownWidget + "/event/eventUuid"]
      )
      .then(() => {
        const items = this.$store.getters[
          this.$store.state.shownWidget + "/event/additionalItems"
        ];
        if (items.length === 0) {
          this.$store.commit(
            this.$store.state.shownWidget + "/global/changePage",
            "BuyerInformation"
          );
        }
        this.items = items.map(item => {
          temp[item.uuid] = {
            selectedOption: null,
            selectedAmount: 0
          };
          item.maxPrice = this.maxPrice(item.items);
          item.minPrice = this.minPrice(item.items);
          return item;
        });
        this.selectedItems = temp;
      });
  }
};
</script>
