<template>
  <div class="text-center border-bottom border-secondary mx-2 page-header mb-3">
    <h1 class="h2 mt-3">{{ eventTitle }}</h1>
    <h5 class="mb-3">{{ $t("pageTitles." + page) }}</h5>
    <div class="text-center text-md-left mb-2" v-if="showBackButton">
      <span class=" pointer" @click="restartFlow(false)">
        <FontAwesomeIcon
          :icon="['fal', 'chevron-left']"
          @click="showModal = false"
          class="mr-2"
        ></FontAwesomeIcon>
        {{ $t("action.restartFlow") }}
      </span>
    </div>
  </div>
</template>

<script>
import groupBy from "lodash.groupby";
import { RestartMixin } from "@/mixins/RestartMixin";

export default {
  name: "PageHeader",
  mixins: [RestartMixin],
  computed: {
    eventTitle: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/event"
      ]["name"];
    },
    page: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/global/page"
      ];
    },
    showBackButton: function() {
      if (
        ![
          "ShowList",
          "Tickets",
          "AdditionalItems",
          "BuyerInformation"
        ].includes(this.page)
      ) {
        return false;
      }
      const shows = this.$store.getters[
        this.$store.state.shownWidget + "/event/shows"
      ];

      if (shows.length === 1 && this.page === "Tickets") {
        return false;
      }
      const days = groupBy(shows, show => {
        return show.starts_at.toISODate();
      });
      if (days.length === 1 && this.page === "ShowList") {
        return false;
      }

      return true;
    }
  }
};
</script>
<style>
.page-header {
  border-bottom-width: 0.15rem !important;
}
</style>
