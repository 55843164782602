var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-grow-1 p-3 row"},_vm._l((_vm.shows),function(showGroup,index){return _c('div',{key:index,staticClass:"col-12"},[_c('h2',{staticClass:"mb-0 text-uppercase-first"},[_vm._v(" "+_vm._s(showGroup[0].startsAtShow.toLocaleString( _vm.$dateTime.DATE_MED_WITH_WEEKDAY ))+" ")]),_c('p',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(showGroup[0].address.street)+", "+_vm._s(showGroup[0].address.postal_code)+" "+_vm._s(showGroup[0].address.city)+" ")]),_vm._l((showGroup),function(show){return _c('div',{key:show.uuid,staticClass:"row mb-5 mb-md-2"},[_c('div',{staticClass:"col-12 col-md-8 text-center text-md-left"},[_c('p',{staticClass:"h5 mb-0"},[_vm._v(" "+_vm._s(show.startsAtShow.toLocaleString(_vm.$dateTime.TIME_24_SIMPLE))+" - "+_vm._s(show.endsAtShow.toLocaleString(_vm.$dateTime.TIME_24_SIMPLE))+" "+_vm._s(show.name)+" ")]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(show.description))])]),_c('div',{staticClass:"col-12 col-md-4 text-center text-md-right"},[(show.sales_stops_at <= _vm.$dateTime.local())?[_vm._v(" "+_vm._s(_vm.$t("showList.saleHasEnded"))+" ")]:_vm._e(),(show.sales_stops_at > _vm.$dateTime.local())?[_c('button',{staticClass:"btn mt-md-3 text-uppercase mr-0",class:{
              'border-ticket-plenty-left btn-outline-ticket-plenty-left':
                show.ticketStatus === _vm.TicketStatus.PLENTY_TICKETS_LEFT,
              'border-ticket-few-left btn-outline-ticket-few-left':
                show.ticketStatus === _vm.TicketStatus.FEW_TICKETS_LEFT,
              'border-ticket-sold-out btn-outline-ticket-sold-out':
                show.ticketStatus === _vm.TicketStatus.SOLD_OUT
            },attrs:{"disabled":show.ticketStatus === _vm.TicketStatus.SOLD_OUT ||
                !Array.isArray(show.tickets)},on:{"click":function($event){return _vm.ticketBtnClicked(show.uuid)}}},[(show.ticketStatus === _vm.TicketStatus.PLENTY_TICKETS_LEFT)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("ticket.status.plentyLeft"))+" ")]):_vm._e(),(show.ticketStatus === _vm.TicketStatus.FEW_TICKETS_LEFT)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("ticket.status.fewLeft"))+" ")]):_vm._e(),(show.ticketStatus === _vm.TicketStatus.SOLD_OUT)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("ticket.status.soldOut"))+" ")]):_vm._e()]),_c('div',{staticClass:"text-muted pt-2"},[(show.priceFrom !== undefined)?_c('TCurrency',{attrs:{"amount":[show.priceFrom, show.priceTo]}}):_vm._e()],1)]:_vm._e()],2)])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }