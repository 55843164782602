import { HttpClient } from "@/http/HttpClient";

export const EventHttpClient = {
  fetchEvent(uuid) {
    return HttpClient().get(`/event/${uuid}`);
  },
  fetchShows(eventUuid) {
    return HttpClient().get(`/event/${eventUuid}/shows`);
  },
  fetchAdditionalItems(eventUuid) {
    return HttpClient().get(`/event/${eventUuid}/additional-items`);
  },
  fetchTicketsForShow(eventUuid, showUuid) {
    return HttpClient().get(`/event/${eventUuid}/shows/${showUuid}/tickets`);
  }
};
