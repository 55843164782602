import { TicketHttpClient } from "@/http/TicketHttpClient";
import { CartHttpClient } from "@/http/CartHttpClient";
import groupby from "lodash.groupby";
import { AdditionalItemHttpClient } from "@/http/AdditionalItemHttpClient";

export const Order = {
  namespaced: true,
  // module assets
  state: () => ({
    orderNumber: null,
    tickets: [],
    additionalItems: [],
    discount: null,
    expiresAt: null,
    reservationExpired: false,
    orderQuestions: []
  }),
  getters: {
    orderQuestions(state) {
      return state.orderQuestions;
    },
    discount(state) {
      return state.discount;
    },
    expirationTime(state) {
      return state.expiresAt;
    },
    reservationExpired(state) {
      return state.reservationExpired;
    },
    orderNumber(state) {
      return state.orderNumber;
    },
    groupedCart(state) {
      let eventUuids = [
        ...new Set([
          ...state.tickets.map(x => x.eventUuid),
          ...state.additionalItems.map(x => x.eventUuid)
        ])
      ];
      let result = {};
      eventUuids.forEach(event => {
        result[event] = {};
        let showUuidForEvent = [
          ...new Set(
            state.tickets
              .filter(x => x.eventUuid === event)
              .map(x => x.showUuid)
          )
        ];
        result[event]["shows"] = {};
        showUuidForEvent.forEach(show => {
          result[event]["shows"][show] = {};
          let tickets = state.tickets.filter(
            x => x.eventUuid === event && x.showUuid === show
          );
          result[event]["shows"][show]["tickets"] = Object.values(
            groupby(tickets, "typeUuid")
          );
          result[event]["shows"][show]["showInfo"] = {
            startsAt: tickets[0].showStartsAt,
            name: tickets[0].showName
          };
        });
        result[event]["shows"] = Object.values(result[event]["shows"]);
        result[event]["additionalItems"] = Object.values(
          groupby(
            state.additionalItems.filter(x => x.eventUuid === event),
            "typeUuid"
          )
        );
        result[event]["info"] = {
          name: result[event]["shows"][0]["tickets"][0][0].eventName
        };
      });
      return Object.values(result);
    },
    //The total price the user has to pay
    totalPrice(state) {
      //Ticket prices
      const ticketPricing = state.tickets.reduce((a, b) => a + b.price, 0);
      const ticketFee = state.tickets.reduce((a, b) => a + b.fee, 0);

      //AdditionalItem prices
      const additionalItemPricing = state.additionalItems.reduce(
        (a, b) => a + b.price,
        0
      );
      const additionalItemFee = state.additionalItems.reduce(
        (a, b) => a + b.fee,
        0
      );

      const discount = state.discount === null ? 0 : state.discount.amount;
      return (
        ticketPricing +
        ticketFee +
        additionalItemPricing +
        additionalItemFee +
        discount
      );
    },
    //The total fees the user has to pay
    totalFees(state) {
      const ticketFee = state.tickets.reduce((a, b) => a + b.fee, 0);
      const additionalItemFee = state.additionalItems.reduce(
        (a, b) => a + b.fee,
        0
      );

      return ticketFee + additionalItemFee;
    }
  },
  actions: {
    addTickets(context, { eventUuid, showUuid, payload }) {
      return TicketHttpClient.reserveTickets(eventUuid, showUuid, payload).then(
        () => {
          context.dispatch("fetchCart");
        }
      );
    },
    addItems(context, { eventUuid, showUuid, payload }) {
      return AdditionalItemHttpClient.reserveItems(
        eventUuid,
        showUuid,
        payload
      ).then(() => {
        context.dispatch("fetchCart");
      });
    },
    fetchCart(context) {
      return CartHttpClient.getCart().then(response => {
        context.commit("updateCart", response.data);
      });
    },
    emptyCart(context) {
      return CartHttpClient.emptyCart().then(() => {
        context.commit("emptyCart");
      });
    },
    redeemCoupon(context, { coupon }) {
      return CartHttpClient.redeemCoupon(coupon).then(() => {
        context.dispatch("fetchCart");
      });
    }
  },
  mutations: {
    setReservationExpired(state, value) {
      state.reservationExpired = value;
    },
    updateCart(state, data) {
      state.tickets = data.tickets.map(x => {
        x.showStartsAt = dateTime.fromISO(x.showStartsAt);
        return x;
      });
      state.additionalItems = data.additionalItems;
      state.discount = data.discount;
      state.expiresAt = dateTime.fromISO(data.expiresAt).toMillis();
    },
    emptyCart(state) {
      state.tickets = [];
      state.additionalItems = [];
      state.discount = [];
    },
    setOrderNumber(state, orderNumber) {
      state.orderNumber = orderNumber;
    },
    setOrderQuestions(state, questions) {
      state.orderQuestions = questions;
    }
  }
};
