import { HttpClient } from "@/http/HttpClient";

export const TicketHttpClient = {
  reserveTickets(eventUuid, showUuid, tickets) {
    return HttpClient().post(
      `/event/${eventUuid}/shows/${showUuid}/tickets/reserve`,
      tickets
    );
  }
};
