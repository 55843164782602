import { HttpClient } from "@/http/HttpClient";

export const UserHttpClient = {
  store(data) {
    return HttpClient().post(`/user`, data);
  },
  login(data) {
    return HttpClient().post(`/user/login`, data);
  },
  requestPasswordReset(data) {
    return HttpClient().post("/user/password/email", data);
  }
};
