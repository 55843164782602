import { HttpClient } from "@/http/HttpClient";
import { UserHttpClient } from "@/http/UserHttpClient";

export const Global = {
  namespaced: true,
  // module assets
  state: () => ({
    page: "Index",
    sessionToken: null,
    initialEventUuid: null,
    widgetUuid: null,
    language: "da",
    currency: "DKK",
    themeUri: "",
    instanceId: null
  }),
  getters: {
    sessionToken(state) {
      return state.sessionToken;
    },
    page(state) {
      return state.page;
    },
    initialEventUuid(state) {
      return state.initialEventUuid;
    },
    language(state) {
      return state.language;
    },
    currency(state) {
      return state.currency;
    },
    themeUri(state) {
      //if (process.env.NODE_ENV === "development") {
      //  return "";
      //}
      return state.themeUri;
    }
  },
  actions: {
    obtainSessionToken(context) {
      return HttpClient()
        .get("token/obtain")
        .then(response => {
          context.commit("setSessionToken", response.data.token);
        });
      //TODO if session is set, check expiration, and keep or refresh depending on expiration
    },
    getWidgetSettings(context, instanceId) {
      const widgetUuid = context.state.widgetUuid;
      return HttpClient(instanceId)
        .get(`widget/${widgetUuid}/settings`)
        .then(response => {
          context.commit("setInitialEventUuid", response.data.eventUuid);
          context.commit("setThemeUri", response.data.settings.theme);
        });
    },
    storeUser(context, data) {
      return UserHttpClient.store(data);
    },
    login(context, data) {
      return UserHttpClient.login(data);
    }
  },
  mutations: {
    changePage(state, page) {
      state.page = page;
    },
    changeLanguage(state, languageKey) {
      state.language = languageKey;
    },
    setSessionToken(state, token) {
      state.sessionToken = token;
    },
    setWidgetUuid(state, uuid) {
      state.widgetUuid = uuid;
    },
    setInstanceId(state, id) {
      state.instanceId = id;
    },
    setInitialEventUuid(state, uuid) {
      state.initialEventUuid = uuid;
    },
    setThemeUri(state, uri) {
      state.themeUri = uri;
    }
  }
};
