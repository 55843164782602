import { ValidationHttpClient } from "@/http/ValidationHttpClient";

const userEmailUnique = value => {
  return ValidationHttpClient.userEmailUnique({ email: value })
    .then(() => {
      return {
        valid: true
      };
    })
    .catch(() => {
      return false;
    });
};

export default {
  validate: userEmailUnique
};
