<template>
  <div class="mx-5">
    <validation-observer ref="questionForm">
      <h3>
        {{ $t("orderQuestion.title") }}
      </h3>
      <div v-if="questionsForOrder.length > 0">
        <div class="mb-2">{{ $t("orderQuestion.perOrderQuestion") }}</div>
        <div
          v-for="question in questionsForOrder"
          :key="'orderQuestion-' + question.id"
        >
          <TInput
            v-if="question.type === 2"
            :label="question.question"
            :rules="{ required: question.is_required === 1 }"
            :value="getResponseValue(question.id, null, question.type)"
            @input="updateResponses(question.id, null, null, $event)"
          ></TInput>

          <div class="form-group" v-if="question.type === 1">
            <validation-provider
              :rules="{ required: question.is_required === 1 }"
              v-slot="{ errors, classes, required }"
              :name="question.question"
              slim
              :vid="'question' + question.id"
            >
              <label
                >{{ question.question
                }}<TRequiredIcon v-if="required"></TRequiredIcon
              ></label>
              <select
                class="custom-select"
                :class="classes"
                :value="getResponseValue(question.id, null, question.type)"
                @input="
                  updateResponses(question.id, null, $event.target.value, null)
                "
              >
                <option selected disabled hidden value="">{{
                  $t("orderQuestion.chooseAnswer")
                }}</option>
                <option
                  :value="option.id"
                  v-for="option in question.options"
                  :key="'option-' + option.id"
                  >{{ option.value }}</option
                >
              </select>
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </div>
      </div>
      <div v-if="questionsForTickets.length > 0" class="mt-6">
        <div class="mb-2">{{ $t("orderQuestion.perTicketQuestion") }}</div>
        <div v-for="(event, eventIndex) in cart" :key="'event' + eventIndex">
          <div
            v-for="(show, showIndex) in event['shows']"
            :key="'show' + showIndex"
          >
            <div
              v-for="(ticketType, ticketTypeIndex) in show['tickets']"
              :key="'ticketType' + ticketTypeIndex"
            >
              <div
                class="mb-6"
                v-for="(ticket, ticketIndex) in ticketType"
                :key="'ticket' + ticketIndex"
              >
                <div class="text-center font-weight-bold">
                  {{
                    $t("orderQuestion.ticketForTime", {
                      ticket: ticket.name,
                      time: show["showInfo"].startsAt.toLocaleString(
                        $dateTimeFormat.DATETIME_WITH_WEEKDAY
                      )
                    })
                  }}
                </div>
                <div
                  v-for="(question, questionIndex) in questionsForTickets"
                  :key="'question' + questionIndex"
                >
                  <TInput
                    v-if="question.type === 2"
                    :label="question.question"
                    :rules="{ required: question.is_required === 1 }"
                    :value="getResponseValue(question.id, null, question.type)"
                    @input="
                      updateResponses(question.id, ticket.uuid, null, $event)
                    "
                  ></TInput>
                  <div class="form-group" v-if="question.type === 1">
                    <validation-provider
                      :rules="{ required: question.is_required === 1 }"
                      v-slot="{ errors, classes, required }"
                      :name="question.question"
                      slim
                      :vid="'question' + question.id + '-' + ticket.uuid"
                    >
                      <label
                        >{{ question.question
                        }}<TRequiredIcon v-if="required"></TRequiredIcon
                      ></label>
                      <select
                        class="custom-select"
                        :class="classes"
                        :value="
                          getResponseValue(
                            question.id,
                            ticket.uuid,
                            question.type
                          )
                        "
                        @input="
                          updateResponses(
                            question.id,
                            ticket.uuid,
                            $event.target.value,
                            null
                          )
                        "
                      >
                        <option selected disabled hidden value="">{{
                          $t("orderQuestion.chooseAnswer")
                        }}</option>
                        <option
                          :value="option.id"
                          v-for="option in question.options"
                          :key="'option-' + option.id"
                          >{{ option.value }}</option
                        >
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <div class="row mt-3">
      <div class="col-12">
        <t-submit-button
          :btn-classes="{ 'btn-success float-right mt-3': true }"
          :btn-text="$t('action.continue')"
          :form="formRef"
          :action="nextPage"
        ></t-submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TInput,
  TRequiredIcon,
  TSubmitButton
} from "@tickethero/vue-component-kit";

export default {
  name: "OrderQuestion",
  components: {
    TInput,
    TRequiredIcon,
    TSubmitButton
  },
  data: function() {
    return {
      responses: [],
      formRef: null
    };
  },
  computed: {
    cart: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/groupedCart"
      ];
    },
    questionsForOrder: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/orderQuestions"
      ]
        .filter(x => x.show_for === 1)
        .sort((a, b) => a.order - b.order)
        .map(item => {
          item.options = item.options.sort((a, b) => a.order - b.order);
          return item;
        });
    },
    questionsForTickets: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/orderQuestions"
      ]
        .filter(x => x.show_for === 2)
        .sort((a, b) => a.order - b.order)
        .map(item => {
          item.options = item.options.sort((a, b) => a.order - b.order);
          return item;
        });
    }
  },
  methods: {
    getResponseValue(questionId, ticketId, type) {
      let responseIndex = this.responses.findIndex(
        x => x.question_id === questionId && x.ticket_id === ticketId
      );
      if (responseIndex === -1) {
        this.responses.push({
          ticket_id: ticketId,
          question_id: questionId,
          option_id: null,
          response: null
        });
        responseIndex = this.responses.length - 1;
      }
      if (type === 1) {
        return this.responses[responseIndex].option_id;
      }
      return this.responses[responseIndex].response;
    },
    updateResponses(questionId, ticketId, option_id, response) {
      let responseIndex = this.responses.findIndex(
        x => x.question_id === questionId && x.ticket_id === ticketId
      );
      let responseObj = {
        ticket_id: ticketId,
        question_id: questionId,
        option_id: option_id,
        response: response
      };
      if (responseIndex === -1) {
        this.responses.push(responseObj);
      } else {
        this.responses[responseIndex] = responseObj;
      }
      this.$store.commit(
        this.$store.state.shownWidget + "/order/setOrderQuestions",
        this.responses
      );
    },
    nextPage() {
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "Payment"
      );
    }
  },
  created() {
    this.$nextTick(() => {
      this.formRef = this.$refs.questionForm;
    });
  }
};
</script>
