import axios from "axios";
import store from "@/store";

export const HttpClient = (instanceId = null) => {
  const options = {
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  };

  let storeNamespace;
  if (instanceId !== null) {
    storeNamespace = instanceId;
  } else {
    storeNamespace = store.state.shownWidget;
  }

  if (store.getters[storeNamespace + "/global/sessionToken"]) {
    options.headers.Authorization = `Bearer ${
      store.getters[storeNamespace + "/global/sessionToken"]
    }`;
  }

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    requestError => {
      //ERROR HANDLING - IE. BUGSNAG
      return Promise.reject(requestError);
    }
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status >= 500) {
        //ERROR HANDLING - IE. BUGSNAG
      }
      return Promise.reject(error);
    }
  );

  return client;
};
