<template>
  <div class="p-1 p-sm-3 mw-100">
    <div class="d-sm-none px-1 text-center">
      <TicketStatusBadge
        :status="ticketStatus.PLENTY_TICKETS_LEFT"
      ></TicketStatusBadge
      ><br />
      <TicketStatusBadge
        :status="ticketStatus.FEW_TICKETS_LEFT"
      ></TicketStatusBadge
      ><br />
      <TicketStatusBadge :status="ticketStatus.SOLD_OUT"></TicketStatusBadge>
    </div>
    <v-calendar
      is-expanded
      class="border-0 mw-100"
      :attributes="shows"
      :min-date="validStart"
      :max-date="validEnd"
      :from-date="initialDate"
      :locale="systemLanguage"
      :is-dark="
        theme === 'https://storage.tickethero.dk/widget/themes/dark.css'
      "
    >
      <template #day-content="{day, attributes}">
        <div
          class="w-100 d-flex align-items-center flex-column py-1 py-sm-0"
          @click="dayClick(day)"
        >
          <div
            class="d-flex justify-content-center align-items-center rounded-circle user-select-none"
            style="width: 35px; height: 35px;"
            :class="{
              pointer:
                attributes !== undefined &&
                attributes.length > 0 &&
                ticketStatusForDay(attributes) !== -1,
              'day-circle bg-ticket-plenty-left text-white':
                ticketStatusForDay(attributes) ===
                ticketStatus.PLENTY_TICKETS_LEFT,
              'day-circle bg-ticket-few-left text-white':
                ticketStatusForDay(attributes) ===
                ticketStatus.FEW_TICKETS_LEFT,
              'day-circle bg-ticket-sold-out text-white':
                ticketStatusForDay(attributes) === ticketStatus.SOLD_OUT,
              'bg-gray text-white': ticketStatusForDay(attributes) === -1
            }"
          >
            {{ day.label }}<br />
          </div>
          <div
            class="d-none d-sm-block"
            :class="{
              pointer:
                attributes !== undefined &&
                attributes.length > 0 &&
                ticketStatusForDay(attributes) !== -1
            }"
          >
            <TicketStatusBadge
              :status="ticketStatusForDay(attributes)"
              badge-design="no-dot"
              :class="{
                invisible: attributes === undefined || attributes.length === 0
              }"
            ></TicketStatusBadge>
          </div>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import TicketStatus from "@/enums/TicketStatus";
import TicketStatusBadge from "@/components/TicketStatusBadge";

export default {
  name: "Calendar",
  components: { TicketStatusBadge },
  data: function() {
    return {
      ticketStatus: TicketStatus
    };
  },
  computed: {
    theme: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/global/themeUri"
      ];
    },
    shows: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/shows"
      ].map(show => {
        return {
          dates: show.starts_at.toJSDate(),
          customData: show
        };
      });
    },
    ticketStatusByDate: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/ticketStatusByDate"
      ];
    },
    validStart: function() {
      return this.shows[0].customData.starts_at;
    },
    validEnd: function() {
      return this.shows[this.shows.length - 1].customData.starts_at;
    },
    initialDate: function() {
      const now = this.$dateTime.local().startOf("day");
      let futureShows = this.shows.filter(
        x => x.customData.starts_at.startOf("day") >= now
      );
      return futureShows[0]?.customData.starts_at.toJSDate() ?? now.toJSDate();
    }
  },
  methods: {
    ticketStatusForDay(attributes) {
      let status = 0;
      if (attributes !== undefined && attributes.length > 0) {
        if (
          attributes[0].customData.starts_at.startOf("day") <
          this.$dateTime.local().startOf("day")
        ) {
          return -1;
        }
        status = this.ticketStatusByDate[
          attributes[0].customData.starts_at.toFormat("y-MM-dd")
        ];
      }
      return status;
    },
    dayClick(e) {
      if (e.attributes.length === 0) {
        return;
      }
      if (e.date < this.$dateTime.local().startOf("day")) {
        return;
      }
      if (
        [0, TicketStatus.SOLD_OUT].includes(
          this.ticketStatusForDay(e.attributes)
        )
      ) {
        return;
      }
      if (e.attributes.length === 1) {
        this.$store
          .dispatch(
            this.$store.state.shownWidget + "/event/fetchTicketsForShow",
            {
              eventUuid: this.$store.getters[
                this.$store.state.shownWidget + "/event/eventUuid"
              ],
              showUuid: e.attributes[0].customData.uuid
            }
          )
          .then(() => {
            this.$store.commit(
              this.$store.state.shownWidget + "/event/setSelectedShowUuid",
              e.attributes[0].customData.uuid
            );
            this.$store.commit(
              this.$store.state.shownWidget + "/global/changePage",
              "Tickets"
            );
          });
      } else if (e.attributes.length > 1) {
        this.$store.commit(
          this.$store.state.shownWidget + "/event/setShowDateLimiter",
          e.id
        );
        this.$store.commit(
          this.$store.state.shownWidget + "/global/changePage",
          "ShowList"
        );
      }
    }
  }
};
</script>

<style>
.vc-day .day-circle {
  transition: transform 0.5s;
}
.vc-day:hover .day-circle {
  transform: scale(1.1);
}
</style>
