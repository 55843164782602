import groupBy from "lodash.groupby";

function restartFlowLogic() {
  const shows = this.$store.getters[
    this.$store.state.shownWidget + "/event/shows"
  ];
  if (shows.length === 1) {
    this.$store.commit(
      this.$store.state.shownWidget + "/global/changePage",
      "Tickets"
    );
  } else {
    const days = groupBy(shows, show => {
      return show.starts_at.toISODate();
    });
    if (days.length === 1) {
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "ShowList"
      );
    } else {
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "Calendar"
      );
    }
  }
}

export const RestartMixin = {
  methods: {
    restartFlow(emptyCart = true) {
      if (emptyCart) {
        this.$store.commit(
          this.$store.state.shownWidget + "/order/setReservationExpired",
          false
        );
        this.$store
          .dispatch(this.$store.state.shownWidget + "/order/emptyCart")
          .then(() => {
            restartFlowLogic.call(this);
          });
      } else {
        restartFlowLogic.call(this);
      }
    }
  }
};
