var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 h-100 d-flex flex-column"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.cart.length > 0),expression:"cart.length > 0"}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("reservationExpiresAt"))+": "),_c('TCountdown',{attrs:{"end":_vm.expirationTime}})],1),(_vm.cart.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('span',{staticClass:"h4 mb-0"},[_vm._v(_vm._s(_vm.$t("cart.title")))]),_c('font-awesome-icon',{staticClass:" pointer align-self-center text-warning",attrs:{"icon":['fal', 'trash-alt'],"title":_vm.$t('cart.action.empty')},on:{"click":_vm.restartFlow}})],1),_vm._l((_vm.cart),function(event,index){return _c('div',{key:index},[_c('h5',[_vm._v(_vm._s(event["info"].name))]),_vm._l((event['shows']),function(show,showIndex){return _c('div',{key:showIndex},[_c('div',{staticClass:"font-weight-bold mb-1 d-inline-block text-uppercase-first"},[_vm._v(" "+_vm._s(show["showInfo"].startsAt.toLocaleString( _vm.$dateTimeFormat.DATETIME_WITH_WEEKDAY ))+" ")]),_vm._l((show['tickets']),function(ticket,ticketIndex){return _c('div',{key:'ticket-' + ticketIndex,staticStyle:{"display":"grid","grid-template-columns":"2fr 1fr 1fr"}},[_c('span',[_vm._v(_vm._s(ticket[0].name))]),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(ticket.length))]),_c('span',{staticClass:"text-right",class:{
                'text-success': ticket[0].price * ticket.length === 0
              }},[_c('TCurrency',{attrs:{"amount":ticket[0].price * ticket.length}})],1)])})],2)}),(event['additionalItems'].length > 0)?_c('h5',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t("cart.additionalItems"))+" ")]):_vm._e(),_vm._l((event['additionalItems']),function(item,itemIndex){return _c('div',{key:'additional-item-' + itemIndex,staticStyle:{"display":"grid","grid-template-columns":"2fr 1fr 1fr"}},[_c('span',[_vm._v(_vm._s(item[0].name)+" "+_vm._s(item[0].description))]),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(item.length))]),_c('span',{staticClass:"text-right",class:{
              'text-success': item[0].price * item.length === 0
            }},[_c('TCurrency',{attrs:{"amount":item[0].price * item.length}})],1)])})],2)})],2),_c('div',{staticClass:"mt-5"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponInvalid),expression:"couponInvalid"}],staticClass:"text-warning small"},[_vm._v(" "+_vm._s(_vm.$t("coupon.error.invalid"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponRequiresUser),expression:"couponRequiresUser"}],staticClass:"text-warning small"},[_vm._v(" "+_vm._s(_vm.$t("coupon.error.requiresUser"))+" ")]),_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couponToRedeem),expression:"couponToRedeem"}],staticClass:"form-control form-control-sm text-uppercase",attrs:{"type":"text","placeholder":_vm.$t('coupon.coupon')},domProps:{"value":(_vm.couponToRedeem)},on:{"input":function($event){if($event.target.composing){ return; }_vm.couponToRedeem=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-success btn-sm",class:{ disabled: _vm.couponToRedeem.length === 0 },on:{"click":_vm.redeemCoupon}},[_vm._v(" "+_vm._s(_vm.$t("coupon.action.redeem"))+" ")])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("cart.feesOnOrder")))]),_c('span',{class:{
            'text-success': _vm.totalFees === 0
          }},[_c('TCurrency',{attrs:{"amount":_vm.totalFees}})],1)]),(_vm.discount !== null)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("cart.discount"))+" "),_c('i',[_vm._v(_vm._s(_vm.discount.coupon))])]),_c('TCurrency',{attrs:{"amount":_vm.discount.amount}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("cart.sumOfOrder")))]),_c('span',{class:{
            'text-success': _vm.totalPrice === 0
          }},[_c('TCurrency',{attrs:{"amount":_vm.totalPrice}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGoToPaymentButton),expression:"showGoToPaymentButton"}]},[_c('button',{staticClass:"btn btn-success btn-block mt-3",on:{"click":_vm.goToPayment}},[_vm._v(" "+_vm._s(_vm.$t("payment.action.pay"))+" ")])])]):_vm._e(),(_vm.cart.length === 0)?_c('p',{staticClass:"text-center text-muted mt-4"},[_vm._v(" "+_vm._s(_vm.$t("cart.isEmpty"))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }