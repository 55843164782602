import Vue from "vue";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VCalendar from "v-calendar";

const { version } = require("./../package.json");
Vue.use(VCalendar);
if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: version
  });
  Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);
}
import {
  configure as veeValidateConfigure,
  ValidationObserver,
  ValidationProvider,
  extend as veeValidateExtend,
  localize
} from "vee-validate";
import { required, regex, email } from "vee-validate/dist/rules";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faTimesCircle,
  faTrashAlt,
  faCheck,
  faChevronLeft
} from "@fortawesome/pro-light-svg-icons";
library.add(faSpinner, faTimesCircle, faTrashAlt, faCheck, faChevronLeft);

const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

import en from "vee-validate/dist/locale/en.json";
import da from "vee-validate/dist/locale/da.json";

localize({
  en: {
    ...en,
    messages: { ...en.messages, ...locales("./en.json").validation.messages }
  },
  da: {
    ...da,
    messages: { ...da.messages, ...locales("./da.json").validation.messages }
  }
});

veeValidateConfigure({
  classes: {
    invalid: ["is-invalid"],
    valid: ["is-valid"]
  }
});

import userEmailUnique from "@/validation-rules/userEmailUnique";
veeValidateExtend("userEmailUnique", userEmailUnique);
veeValidateExtend("required", required);
veeValidateExtend("regex", regex);
veeValidateExtend("email", email);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

import { DateTime, Settings } from "luxon";
Settings.defaultZoneName = "Europe/Copenhagen";
window.dateTime = DateTime;
Vue.prototype.$dateTime = DateTime;

Vue.use(globalDataPlugin);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "da",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "da",
  messages: loadLocaleMessages()
});

import Echo from "laravel-echo";

//DO NOT REMOVE! Pusher is needed for laravel-echo to work
//eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js";
import globalDataPlugin from "@/globalDataPlugin";

Vue.prototype.$ws = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTH_ENDPOINT,
  namespace: ""
});
