var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1 p-sm-3 mw-100"},[_c('div',{staticClass:"d-sm-none px-1 text-center"},[_c('TicketStatusBadge',{attrs:{"status":_vm.ticketStatus.PLENTY_TICKETS_LEFT}}),_c('br'),_c('TicketStatusBadge',{attrs:{"status":_vm.ticketStatus.FEW_TICKETS_LEFT}}),_c('br'),_c('TicketStatusBadge',{attrs:{"status":_vm.ticketStatus.SOLD_OUT}})],1),_c('v-calendar',{staticClass:"border-0 mw-100",attrs:{"is-expanded":"","attributes":_vm.shows,"min-date":_vm.validStart,"max-date":_vm.validEnd,"from-date":_vm.initialDate,"locale":_vm.systemLanguage,"is-dark":_vm.theme === 'https://storage.tickethero.dk/widget/themes/dark.css'},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
return [_c('div',{staticClass:"w-100 d-flex align-items-center flex-column py-1 py-sm-0",on:{"click":function($event){return _vm.dayClick(day)}}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center rounded-circle user-select-none",class:{
            pointer:
              attributes !== undefined &&
              attributes.length > 0 &&
              _vm.ticketStatusForDay(attributes) !== -1,
            'day-circle bg-ticket-plenty-left text-white':
              _vm.ticketStatusForDay(attributes) ===
              _vm.ticketStatus.PLENTY_TICKETS_LEFT,
            'day-circle bg-ticket-few-left text-white':
              _vm.ticketStatusForDay(attributes) ===
              _vm.ticketStatus.FEW_TICKETS_LEFT,
            'day-circle bg-ticket-sold-out text-white':
              _vm.ticketStatusForDay(attributes) === _vm.ticketStatus.SOLD_OUT,
            'bg-gray text-white': _vm.ticketStatusForDay(attributes) === -1
          },staticStyle:{"width":"35px","height":"35px"}},[_vm._v(" "+_vm._s(day.label)),_c('br')]),_c('div',{staticClass:"d-none d-sm-block",class:{
            pointer:
              attributes !== undefined &&
              attributes.length > 0 &&
              _vm.ticketStatusForDay(attributes) !== -1
          }},[_c('TicketStatusBadge',{class:{
              invisible: attributes === undefined || attributes.length === 0
            },attrs:{"status":_vm.ticketStatusForDay(attributes),"badge-design":"no-dot"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }