<template>
  <div class="d-flex flex-grow-1 flex-column p-3">
    <div class="flex-grow-1">
      <h1 class="h3 mb-0">
        {{ show.name }}
        <span class="d-inline-block text-uppercase-first">{{
          show.starts_at.toLocaleString($dateTimeFormat.DATETIME_WITH_WEEKDAY)
        }}</span>
      </h1>
      <p class="font-italic">
        {{ show.address.street }},
        {{ show.address.postal_code }}
        {{ show.address.city }}
      </p>
      <div
        class="row mb-5 mb-md-4"
        v-for="(ticket, index) in tickets"
        :key="index"
      >
        <div class="col-12 col-md-9">
          <h5 class="mb-0">{{ ticket.name }}</h5>
          <p class="my-0">{{ ticket.description }}</p>
          <TCurrency :amount="ticket.price"></TCurrency>
          <small v-if="ticket.fee > 0">
            {{ $t("fee") }} <TCurrency :amount="ticket.fee"></TCurrency>
          </small>
        </div>
        <div class="col-12 col-md-3 text-center">
          <TNumberInput
            :disabled="ticket.TicketStatus === TicketStatus.SOLD_OUT"
            v-if="tempTicketAmounts.hasOwnProperty(ticket.uuid)"
            v-model="tempTicketAmounts[ticket.uuid]"
          ></TNumberInput>
          <ticketStatusBadge :status="ticket.ticketStatus"></ticketStatusBadge>
        </div>
      </div>
    </div>
    <div>
      <div class="alert alert-warning" v-if="notEnoughTicketsError">
        {{ $t("ticket.error.notEnoughTickets") }}
      </div>
      <div class="alert alert-warning" v-if="ticketMinimumAmountNotReached">
        {{
          $t("ticket.error.ticketMinimumAmountNotReached", {
            min: ticketMinimumAmountNotReachedAmount
          })
        }}
      </div>
      <div class="alert alert-warning" v-if="ticketMaximumAmountReached">
        {{
          $t("ticket.error.ticketMaximumAmountReached", {
            max: ticketMaximumAmountReachedAmount
          })
        }}
      </div>
      <button
        class="btn btn-success float-right"
        @click="addTicketsToCart"
        :disabled="totalTicketsAdded === 0"
      >
        {{ $t("cart.action.add") }}
      </button>
    </div>
  </div>
</template>
<script>
import { TCurrency, TNumberInput } from "@tickethero/vue-component-kit";
import TicketStatusBadge from "@/components/TicketStatusBadge";
import TicketStatus from "@/enums/TicketStatus";
import ApiResponseCode from "@/enums/ApiResponseCode";

export default {
  name: "Tickets",
  components: { TNumberInput, TCurrency, TicketStatusBadge },
  data() {
    return {
      TicketStatus: TicketStatus,
      tempTicketAmounts: {},
      notEnoughTicketsError: false,
      ticketMinimumAmountNotReached: false,
      ticketMinimumAmountNotReachedAmount: 0,
      ticketMaximumAmountReached: false,
      ticketMaximumAmountReachedAmount: 0
    };
  },
  computed: {
    totalTicketsAdded: function() {
      return Object.entries(this.tempTicketAmounts).reduce(
        (a, b) => a + b[1],
        0
      );
    },
    tickets: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/ticketsForActiveShow"
      ];
    },
    show: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/event/activeShow"
      ];
    }
  },
  methods: {
    addTicketsToCart() {
      this.notEnoughTicketsError = false;
      this.ticketMinimumAmountNotReached = false;
      let tickets = [];
      if (this.totalTicketsAdded === 0) {
        return;
      }
      let keys = Object.keys(this.tempTicketAmounts);
      keys.forEach(key => {
        tickets.push({
          id: key,
          amount: this.tempTicketAmounts[key]
        });
      });

      this.$store
        .dispatch(this.$store.state.shownWidget + "/order/addTickets", {
          eventUuid: this.$store.getters[
            this.$store.state.shownWidget + "/event/eventUuid"
          ],
          showUuid: this.$store.getters[
            this.$store.state.shownWidget + "/event/activeShow"
          ].uuid,
          payload: { tickets: tickets }
        })
        .then(() => {
          this.$store.commit(
            this.$store.state.shownWidget + "/global/changePage",
            "AdditionalItems"
          );
        })
        .catch(error => {
          if (error.response.data?.error?.code === "10001") {
            this.notEnoughTicketsError = true;
          }
          if (
            error.response.data?.error?.code ===
            ApiResponseCode.MINIMUM_TICKET_AMOUNT_NOT_REACHED
          ) {
            this.ticketMinimumAmountNotReached = true;
            this.ticketMinimumAmountNotReachedAmount =
              error.response.data.error.meta.minimumAmount;
          }
          if (
            error.response.data?.error?.code ===
            ApiResponseCode.MAXIMUM_TICKET_AMOUNT_REACHED
          ) {
            this.ticketMaximumAmountReached = true;
            this.ticketMaximumAmountReachedAmount =
              error.response.data.error.meta.maximumAmount;
          }
        });
    }
  },
  mounted() {
    this.tickets.forEach(item => {
      this.$set(this.tempTicketAmounts, item.uuid, 0);
    });
  }
};
</script>
