let globalDataPlugin = function() {};

globalDataPlugin.install = function(Vue) {
  Vue.prototype.$dateTimeFormat = {
    DATETIME_WITH_WEEKDAY: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric"
    }
  };
  Vue.mixin({
    computed: {
      systemLanguage: function() {
        return this.$store.getters["global/language"];
      }
    }
  });
};

export default globalDataPlugin;
