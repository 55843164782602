<template>
  <div
    class="position-absolute d-flex justify-content-center bg-opacity-7 rounded backdrop-blur-3 backdrop-grayscale-60 backdrop-filter"
    style="top: 0; left: 0; right: 0; bottom: 0; z-index: 100;"
  >
    <div :class="contentClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalOverlayMessage",
  props: {
    contentClasses: {
      type: [String, Array],
      default: "align-self-center text-center"
    }
  }
};
</script>
