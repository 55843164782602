<template>
  <div class="d-flex flex-grow-1 p-3 row">
    <div class="col-12" v-for="(showGroup, index) in shows" :key="index">
      <h2 class="mb-0 text-uppercase-first">
        {{
          showGroup[0].startsAtShow.toLocaleString(
            $dateTime.DATE_MED_WITH_WEEKDAY
          )
        }}
      </h2>
      <p class="font-italic">
        {{ showGroup[0].address.street }},
        {{ showGroup[0].address.postal_code }}
        {{ showGroup[0].address.city }}
      </p>
      <div v-for="show in showGroup" :key="show.uuid" class="row mb-5 mb-md-2">
        <div class="col-12 col-md-8 text-center text-md-left">
          <p class="h5 mb-0">
            {{ show.startsAtShow.toLocaleString($dateTime.TIME_24_SIMPLE) }}
            -
            {{ show.endsAtShow.toLocaleString($dateTime.TIME_24_SIMPLE) }}
            {{ show.name }}
          </p>
          <p class="my-0">{{ show.description }}</p>
        </div>
        <div class="col-12 col-md-4 text-center text-md-right">
          <template v-if="show.sales_stops_at <= $dateTime.local()">
            {{ $t("showList.saleHasEnded") }}
          </template>
          <template v-if="show.sales_stops_at > $dateTime.local()">
            <button
              :disabled="
                show.ticketStatus === TicketStatus.SOLD_OUT ||
                  !Array.isArray(show.tickets)
              "
              class="btn mt-md-3 text-uppercase mr-0"
              :class="{
                'border-ticket-plenty-left btn-outline-ticket-plenty-left':
                  show.ticketStatus === TicketStatus.PLENTY_TICKETS_LEFT,
                'border-ticket-few-left btn-outline-ticket-few-left':
                  show.ticketStatus === TicketStatus.FEW_TICKETS_LEFT,
                'border-ticket-sold-out btn-outline-ticket-sold-out':
                  show.ticketStatus === TicketStatus.SOLD_OUT
              }"
              @click="ticketBtnClicked(show.uuid)"
            >
              <span
                v-if="show.ticketStatus === TicketStatus.PLENTY_TICKETS_LEFT"
              >
                {{ $t("ticket.status.plentyLeft") }}
              </span>
              <span v-if="show.ticketStatus === TicketStatus.FEW_TICKETS_LEFT">
                {{ $t("ticket.status.fewLeft") }}
              </span>
              <span v-if="show.ticketStatus === TicketStatus.SOLD_OUT">
                {{ $t("ticket.status.soldOut") }}
              </span>
            </button>
            <div class="text-muted pt-2">
              <TCurrency
                v-if="show.priceFrom !== undefined"
                :amount="[show.priceFrom, show.priceTo]"
              ></TCurrency>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import groupby from "lodash.groupby";
import TicketStatus from "@/enums/TicketStatus";
import { TCurrency } from "@tickethero/vue-component-kit";

export default {
  name: "ShowList",
  components: {
    TCurrency
  },
  data: function() {
    return {
      TicketStatus: TicketStatus
    };
  },
  computed: {
    shows: function() {
      let data = this.$store.getters[
        this.$store.state.shownWidget + "/event/shows"
      ];
      const dateLimit = this.$store.getters[
        this.$store.state.shownWidget + "/event/showDateLimiter"
      ];
      if (dateLimit !== null) {
        const dateTimeLimit = this.$dateTime.fromISO(dateLimit);
        data = data.filter(x => x.starts_at.hasSame(dateTimeLimit, "day"));
      }
      data = data.map(item => {
        item.startsAtShow = item.starts_at;
        item.endsAtShow = item.ends_at;
        item.priceFrom = item.tickets?.reduce((prev, curr) =>
          prev.price < curr.price ? prev : curr
        ).price;
        item.priceTo = item.tickets?.reduce((prev, curr) =>
          prev.price > curr.price ? prev : curr
        ).price;
        return item;
      });

      data = Object.values(
        groupby(data, item => {
          return item.startsAtShow.toLocaleString(this.$dateTime.DATE_SHORT);
        })
      ).sort((a, b) => {
        return a[0].startsAtShow - b[0].startsAtShow;
      });

      data.forEach((item, index) => {
        data[index] = item.sort((a, b) => {
          return a.startsAtShow - b.startsAtShow;
        });
      });

      return data;
    }
  },
  methods: {
    ticketBtnClicked(showUuid) {
      this.$store.commit(
        this.$store.state.shownWidget + "/event/setSelectedShowUuid",
        showUuid
      );
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "Tickets"
      );
    }
  },
  created() {
    let data = this.$store.getters[
      this.$store.state.shownWidget + "/event/shows"
    ];
    const dateLimit = this.$store.getters[
      this.$store.state.shownWidget + "/event/showDateLimiter"
    ];
    if (dateLimit !== null) {
      const dateTimeLimit = this.$dateTime.fromISO(dateLimit);
      data = data.filter(x => x.starts_at.hasSame(dateTimeLimit, "day"));
    }
    data.forEach(x => {
      this.$store.dispatch(
        this.$store.state.shownWidget + "/event/fetchTicketsForShow",
        {
          eventUuid: this.$store.getters[
            this.$store.state.shownWidget + "/event/eventUuid"
          ],
          showUuid: x.uuid
        }
      );
    });
  }
};
</script>
